import myRequest from '../http/index.js'

export function getnewslistAPI (params) {
  return myRequest({
    url: '/api/pc/getlistnews',
    params,
    method: 'get'
  })
}
export function getnewsinfoAPI (params) {
  return myRequest({
    url: '/api/pc/getinfonews',
    params,
    method: 'get'
  })
}
export function upPhoneAPI (params) {
  return myRequest({
    url: '/api/pc/addcontent',
    params,
    method: 'post'
  })
}
export function getlistbannerAPI (params) {
  return myRequest({
    url: '/api/pc/getlistbanner',
    params,
    method: 'get'
  })
}
<template>
  <div class="nav ">
      <el-row  class="flex-between header container">
        <div class="head-left">
          <img src="@/assets/main/logo.png" alt="" />
          <ul>
            <li>
              <router-link to="/index">首页</router-link>
            </li>
           
            <!-- <li>
              <router-link to="#">少儿乒乓球</router-link>
            </li> -->
            <li>
              <router-link to="/newslist">动态</router-link>
            </li>
            <li>
              <router-link to="#">考证考级</router-link>
            </li>
            
          </ul>
        </div>
        

      </el-row>
  </div>
</template>

<script>
import { mapGetters,mapMutations } from 'vuex'
export default {
  components: {},
  computed: {
    ...mapGetters(["readMsgNum" ]),
    islogin () {
      var flag = localStorage.getItem('token')
      if (flag == null || flag == '') {
        return false
      } else {
        return true
      }
    },
    userinfo(){
      var userinfo=JSON.parse(localStorage.getItem("userinfo")); 
      return userinfo
    }
  },
  watch: {
    $route(to,from){
      
       if(JSON.stringify(to.query)=="{}"){
          this.getlist()
       }
        
      
    }
  },
  mounted () {
    this.getlist()
  },
   
  data () {
    return {}
  },

  methods: {
    ...mapMutations(["setreadMsgNum"]),
    getlist(){
      
   },
  }
}
</script>

<style lang="scss" scope>
$hoverbg:#00A1E8;
.nav{
  width: 100%;
  min-width: 1200px;
  box-shadow: 0px 2px 11px 0px rgba(0,0,0,0.07); 
}
.header {
  // max-width: 1200px;
  margin: 0 auto;
  height: 60px;
  
  background-color: #fff;
  padding: 10px 30px;
  .head-left {
    flex: 1;
    display: flex;
    justify-content: space-between;
    img {
      width: 118px;
    }
    ul {
      display: flex;
      align-items: center;
      margin-left: 30px;
      li {
        margin-left: 40px;
        .router-link-active,
        .myactive {
          color: #00A1E8;
           
        }
        a{
          position: relative;
          height: 80px;
          font-size: 14px;
        }       
      }
    }
  }
  .head-right{
    display: flex;
    align-items: center;
    >div{
        display: flex;
        align-items: center;
        border-radius: 30px;
        padding:10px 20px;
        border: 1px solid #00A1E8;
        margin-right: 10px;
        cursor: pointer;
        transition: all .21s;
        img{
          width: 15px;     
          margin-right: 5px;
        }
        span{
          color: #00A1E8;
          font-size: 12px;  
          transition: all .21s;       
        }
    }
    div:hover{
      background-color: $hoverbg;
      span{
          color: #fff;       
      }
    }
   
     
  }
}
 
</style>

<template>
<div class="footer">
  <div class="container " >
      <el-row :gutter="24" class="ftxt">
					<el-col  :sx="24" :sm="12" :lg="12"  class="col">
						 <div  class="view">
              <img src="../assets/main/logo.png" alt="">	
              <div class="v-right">
                <p>公司信息</p>
                <div class="line"></div>
                <div class="v2">
                  <p>
                    <span>关于我们</span><span>联系我们</span>
                  </p>
                  <p>
                    <span>商务合作</span><span>营业执照</span>
                  </p>
                </div>
              </div>				
						</div>
					</el-col>	
					<el-col  :sx="24" :sm="12" :lg="12"  class="col">
						 <div  class="view">
              <div class="v-right">
                <p>公司信息</p>
                <div class="line"></div>
                <div class="v2">
                  <img src="../assets/main/ewm.png" alt="">	
                </div>
              </div>
						</div>
					</el-col>
					 
			</el-row>
      <p style="display: flex;justify-content: center;">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #665;"> 备案号：沪ICP备2022033820号-1</a>
       
      </p>
  </div>
  <div class="bgblack">
    <div class="container " >
      <el-row :gutter="24" class="fform">
					<el-col  :sx="24" :sm="12" :lg="12"  class="col">
						 <div  class="view">
              <img src="../assets/main/people.png" alt="">	
              <p>领 取 体 验 课</p>
						</div>
					</el-col>	
					<el-col  :sx="24" :sm="12" :lg="12"  class="col">
						 <div  class="viewform">
               <div class="inp">
                  <img src="../assets/main/phone.png" alt="">	
                  <input type="text" placeholder="请输入手机号" v-model="phone">
               </div>
               <div class="btn" @click="upphone">
                立即领取
               </div>
						</div>
					</el-col>
					 
			</el-row>
  </div>
  </div>
	
   
</div>
</template>

<script>
import {upPhoneAPI} from "@/api/common"
export default {

  data () {
    return {
       phone:''
    }
  },
  created () {

  },
  methods: {
    upphone(){
      console.log(13)
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.phone)) {
        this.$message("请填写正确手机号")
        return false;
      } 
      upPhoneAPI({phone:this.phone}).then(res=>{
        this.$message.success("提交成功")
      })
    },
    touserurl (url) {
      this.$router.push({ path: url })
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.footer{
	width: 100%;
  background: #f8f8f8;
	 
  .ftxt{
    display: flex;
    align-items: center;
    .view{
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 120px;
      }
      .v-right{
        display: flex;
        align-items: center;
        margin-left: 40px;
        >p{
          font-weight: bold;
          font-size: 14px;  
        }
        .line{
          width: 1px;
          height: 43px;
          border: 1px solid #00a1e8;
          background-color:#00a1e8 ;
          margin: 0 10px;
        }
        >div{
          p{
            line-height: 26px;
            span{
              margin: 0 5px;
              color: #666666;
              font-size: 14px;  
            }
          }
          img{
            margin-left: 40px;
          }
        }
      }
    }
    
  }
  .bgblack{
    width: 100%;
    height: 70px;
    background: rgba(0,0,0,0.61);
  }
	.fform{
    display: flex;
    align-items: center;
    
    .view{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 70px;
      img{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 120px;
      }
      p{
        color: #fff;
        font-weight: bold;
        font-style: italic;
      }
    }
    .viewform{
      display: flex;
      align-items: center;
      justify-content: center;
      .inp{
        background-color: #fff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        height: 35px;
        padding: 0 10px;
        
        img{
          width: 20px;
        }
        input{
          width: 120px;
          margin-left: 10px;
        }
      }
      .btn{
        width: 200px;
        height: 35px;
        background: #62e29c;
        border-radius: 22px;
        text-align: center;
        line-height: 35px;
        font-weight: bold;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}

</style>
